<template>
    <div style="text-align: left;font-size: 12px;">
        <el-card>
            <h2 class="text-center m-b-50">发布视频</h2>
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="视频分类" prop="enterpriseVideoAlbumId">
                    <el-select v-model="form.enterpriseVideoAlbumId" placeholder="请选择视频分类" size="mini">
                        <el-option v-for="item in classifyList" :key="item.id" :label="item.videoAlbumName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="您的位置" prop="itemAddr">
                    <el-input v-model="form.itemAddr" type="text" :rows="2" autocomplete="off" placeholder="请选择您的位置" @focus="form.itemAddrLatitude?'':onAddrSelect()" />
                    <el-button @click="onAddrSelect()" icon="el-icon-location" type="text">选择</el-button>
                </el-form-item>
                <el-form-item label="视频来源" prop="videoAlbumItemEnum">
                    <template>
                        <el-radio v-model="form.videoAlbumItemEnum" :label="1">本地视频</el-radio>
                        <el-radio v-model="form.videoAlbumItemEnum" :label="2">网络链接/口令</el-radio>
                    </template>
                </el-form-item>
                <el-form-item label="本地视频" prop="itemUrl_1" v-if="form.videoAlbumItemEnum==1">
                    <uploadVideo @uploadVideoBack="uploadVideoBack" @uploadVideoFirstImgBack="uploadVideoFirstImgBack">
                        <div class="videobox" v-if="form.itemUrl_1">
                            <video :src="$oucy.ossUrl+form.itemUrl_1" class="video"></video>
                            <i class="el-icon-refresh refresh"></i>
                        </div>
                        <i class="el-icon-plus avatar-uploader-icon videoAdd" v-else>
                            <div class="f12">添加视频</div>
                        </i>
                    </uploadVideo>
                </el-form-item>
                <el-form-item label="网络链接/口令" prop="itemUrl_2" v-if="form.videoAlbumItemEnum==2">
                    <el-input v-model="form.itemUrl_2" @focus="itemUrl_2=null" type="text" clearable placeholder="请复制视频链接或口令到输入框" @change="feituiVideoParse"></el-input>
                    <div class="f12 c-2">支持：抖音/火山/快手/美拍/皮皮搞笑/皮皮虾/刷宝短视频/今日头条/腾讯微视/小咖秀/西瓜视频/最右/梨视频</div>
                    <div class="f12 myerr" v-if="itemUrl_2!=null">
                        <span  class="c-f00" v-if="itemUrl_2==1">正在解释链接</span>
                        <span  class="c-f00" v-if="itemUrl_2==2">缺少封面,请更换链接</span>
                        <span  class="c-f00" v-if="itemUrl_2==3">缺少视频,请更换链接</span>
                        <span  class="c-f00" v-if="itemUrl_2==4">链接错误,请更换链接</span>
                    </div>
                </el-form-item>
                <el-form-item label="视频简介" prop="itemInfo">
                    <div style="">
                        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" placeholder="请输入视频简介" maxlength="200" show-word-limit v-model="form.itemInfo">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="关联内容">
                    <el-radio-group v-model="form.itemContentType">
                        <el-radio :label="1">关联店铺</el-radio>
                        <el-radio :label="2">关联商品</el-radio>
                        <el-radio :label="3" disabled>关联新闻</el-radio>
                    </el-radio-group>
                    <div class="items" v-if="form.itemContentType==1">
                        已选店铺：[<span class="c-0">{{enterprise.enterpriseShopName}}</span>]
                    </div>
                    <el-card shadow="never" style="margin-bottom: 25px;" v-if="form.itemContentType==2">
                        <div slot="header" class="my-header">
                            <div>产品列表 <el-input v-model="spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 300px;"></el-input>
                                <el-button type="default" size="mini" @click="search">搜索</el-button> <span v-if="form.itemContent_2 && form.itemContent_2.spuName" class="c-2">已选商品：[<span class="c-0">{{form.itemContent_2.spuName}}</span>]</span>
                            </div>
                        </div>
                        <div>
                            <template>
                                <el-table :data="prlductData.content" style="width: 100%" @row-click="rowClick">
                                    <el-table-column width="55">
                                        <template slot-scope="scope">
                                            <el-checkbox v-model="true_" readonly="true" v-if="multipleSelection.indexOf(scope.row.id)>-1"></el-checkbox>
                                            <el-checkbox v-model="false_" readonly="true" v-else></el-checkbox>
                                            <div style="position:absolute;left:0;top: 0;height: 100%;width: 100%; z-index: 1000;"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column type="index" label="编号">
                                    </el-table-column>
                                    <el-table-column prop="date" label="产品封面" width="100">
                                        <template slot-scope="scope">
                                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                                        <template slot-scope="scope">
                                            <div>{{scope.row.spuName}}</div>
                                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="brand.brandName" label="所属分类">
                                        <template slot-scope="scope">
                                            <span v-for="item in byLtUserAuthAndClassifyCategoryList" v-if="scope.row.classify.id==item.id">{{item.classifyName}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuPrise" label="当前价格">
                                        <template slot-scope="scope">
                                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                            <div>
                                <el-divider></el-divider>
                                <div class="df-jc-s-b">
                                    <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">创建</el-button>
                    <el-button type="primary" @click="feituiVideoParse">feituiVideoParse</el-button>
                    <!-- <el-button>保存</el-button> -->
                </el-form-item>
            </el-form>
        </el-card>
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
       <First ref="First"></First>    
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import addr from "@/components/Addr";
import { furnitureClassify, spu, ticket, enterprisevideoalbum } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"
import axios from 'axios'


export default {
    name: "Create",
    components: {
        addr
    },
    data() {
        return {
            rules: {
                enterpriseVideoAlbumId: [
                    { required: true, message: '请选择视频分类', trigger: 'blur' },
                ],
                itemAddr: [
                    { required: true, message: '请选择您的位置', trigger: 'blur' },
                ],
                itemUrl_1: [
                    { required: true, message: '请先上传视频', trigger: 'blur' },
                ],
                itemUrl_2: [
                    { required: true, message: '请输入网络链接/口令', trigger: 'blur' },
                ],
                itemInfo: [
                    { required: true, message: '请输入视频简介', trigger: 'blur' },
                ],

            },
            multipleSelection: [],
            prlductData: {},
            form: {
                enterpriseVideoAlbumId: null,
                enterpriseId: null,
                itemUrl: null,
                itemUrl_1: null,
                itemUrl_2: null,
                itemContent: null,
                itemContent_1: null,
                itemContent_2: null,
                itemInfo: null,
                itemAddrLatitude: null,
                itemAddrLongtitude: null,
                itemAddr: null,
                videoAlbumItemEnum: 1,
            },
            Kinds: [{
                value: 0,
                label: '满减券'
            }, {
                value: 1,
                label: '现金券'
            }, ],
            classify: 3,
            value1: null,
            color1: null,
            checked: null,
            imageUrl: '',
            // 选中列表
            select: {},
            classify: [],
            // 店内分类列表
            byLtUserAuthAndClassifyCategoryList: [],
            // 指定
            spuName: null,
            timeoutID: null,
            limit: 10,
            start: 0,
            prlductData: {},

            classifyDatas: [],
            true_: true,
            false_: false,

            addrSelectVisible: false,
            classifyList: [],
            enterprise: {},
            feituiVideoParseObject: {},
            itemUrl_2:null,
        }
    },
    mounted() {
        let enterprise = this.enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.form.enterpriseId = enterprise.id
        this.form.enterpriseVideoAlbumId = this.$route.query.enterpriseVideoAlbumId
        // 查询分类
        this.getAllEnterpriseVideoAlbumsClient()
        // 查询商品
        this.findAllFurnitureSpu()

        this.$refs.First.gitUrlFirstImg('https://aliyunoss.lesoujia.com/dynamic-boss/c303918447794be49ec2b4889671f7b2.mp4',(e)=>{
            console.log(e)
        })

    },
    computed: {
        // 计算属性的 getter
        'ticketName': function() {
            // `this` 指向 vm 实例
            if (this.form.ticketLimitFee && this.form.ticketFee) {
                let ticketName = '满' + this.form.ticketLimitFee + '减' + this.form.ticketFee + '券'
                this.form.ticketName = ticketName
                return ticketName
            } else
                return null
        }
    },
    methods: {
        getAllEnterpriseVideoAlbumsClient() {
            enterprisevideoalbum.getAllEnterpriseVideoAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                this.classifyList = res
            })
        },
        addEnterpriseVideoAlbumItem() {
            enterprisevideoalbum.addEnterpriseVideoAlbumItem()
        },
        onAddrSelect: function() {
            this.addrSelectVisible = true;
        },
        addrSelectHandle(addr) {
            console.log(addr)
            this.$refs['form'].clearValidate('itemAddr')
            this.addrSelectVisible = false;
            let thisAddr = addr.address + '(' + addr.name + ')';

            this.form.itemAddrLatitude = addr.location.lat
            this.form.itemAddrLongtitude = addr.location.lng
            this.form.itemAddr = thisAddr;


            // this.clearValidate('roomAddrName')
        },
        uploadVideoBack(e) {
            this.form.itemUrl_1 = e
            this.$refs['form'].clearValidate('itemUrl_1')
            console.log(e)
            this.uploadNetFile2oss(e)
        },
        uploadVideoFirstImgBack(v){
            console.log(v)
        },
        uploadNetFile2oss(oss_video_name) {
            let snapshot_path = this.$oucy.ossUrlPrefixVideo+'/' + oss_video_name + '?x-oss-process=video/snapshot,t_10000,m_fast';
            this.$oucy.uploadNetFile2oss(snapshot_path).then(res => {

            })
        },
        handleClick() {},

        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.itemContentType == 1) {
                        this.form.itemContent = this.enterprise.id
                    } else {
                        this.form.classify = null
                    }
                    if (this.form.itemContentType == 2) {
                        if (!this.form.itemContent_2 || this.form.itemContent_2.length == 0) {
                            this.$alert('请先选择指定商品')
                            return
                        }

                        this.form.itemContent = this.form.itemContent_2[0]

                    }
                    if (this.form.videoAlbumItemEnum == 1) {
                        this.form.itemUrl = this.form.itemUrl_1
                    }
                    if (this.form.videoAlbumItemEnum == 2) {
                        if(this.itemUrl_2){
                            return
                        }
                        this.form.itemUrl = this.feituiVideoParseObject.videoUrl
                        this.form.itemCover = this.feituiVideoParseObject.videoImg
                    }
                    console.log(this.form)
                    this.addEnterpriseVideoAlbumItem()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        feituiVideoParse() {
            this.itemUrl_2 = null
            if (!this.form.itemUrl_2) return
            this.itemUrl_2 = 1
            axios.post('https://unicloud.lesoujia.com/h5-feitui-video-parse', {
                    url: this.form.itemUrl_2,
                })
                .then((res) => {
                    if (res.success) {
                        let { videoImg, videoUrl, videoDesc } = result
                        if (!videoImg) {
                            this.$message('缺少封面');
                            this.itemUrl_2 = 2
                            return;
                        }
                        if (!videoUrl) {
                            this.$message('缺少视频');
                            this.itemUrl_2 = 3
                            return;
                        }
                        this.$message('解释成功');
                        this.itemUrl_2 = 0
                        this.feituiVideoParseObject=result
                        this.form.itemInfo=videoDesc

                    } else {
                        this.itemUrl_2 = 4
                        this.$message('链接错误')
                    }
                    console.log(res);
                })
                .catch((error) => {
                    this.itemUrl_2 = 4
                    this.$message('链接错误')
                });

        },
        /**
         * 查询店内分类
         * @return {[type]} [description]
         */
        findByLtUserAuthAndClassifyCategory() {
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
                this.byLtUserAuthAndClassifyCategoryList = res
            })
        },
        // 选择分类
        setSelect(v) {
            let index = this.classify.indexOf(v.id)
            if (index > -1) {
                this.classify.splice(index, 1)
            } else {
                this.classify.push(v.id)
            }
            // this.select = v
        },
        rowClick(row, column, event) {
            console.log(1)
            // this.timeoutID && clearTimeout(this.timeoutID)
            // this.timeoutID =setTimeout(()=>{
            this.setSput(row)
            // },500)
        },
        // 选择商品
        setSput(v) {
            console.log(v)
            this.multipleSelection = []
            if (this.form.itemContent_2 && v.id == this.form.itemContent_2.id) {
                this.form.itemContent_2 = null
            } else {
                this.form.itemContent_2 = v
                this.multipleSelection.push(v.id)
            }
            // this.select = v
        },

        hasImportImg(v) {
            return v.startsWith('//')
        },
        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            this.findAllFurnitureSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.findAllFurnitureSpu();
        },
        // 查询商品
        findAllFurnitureSpu() {
            // /spu/findAllFurnitureSpuClient
            spu.findAllFurnitureSpu({
                spuName: this.spuName,
                limit: this.limit,
                start: this.start,
                isShelves: true,
                spuAuditStatus: 2,
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res
            });
        },
        addEnterpriseVideoAlbumItem() {
            enterprisevideoalbum.addEnterpriseVideoAlbumItem(this.form).then(res => {
                this.$message('创建成功')
                this.$oucy.back()
                console.log(res)
            })
        },
    }

}
</script>
<style scoped lang="less">
.items {
    display: flex;
}

.item {
    padding: 4px 20px;
    line-height: 1.2;
    background: #FFFFFF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    position: relative;
    border: 1px solid #EAEAEA;

    &.select {
        border: 1px solid #2090FF;

        .corner {
            display: inline-block;
        }
    }
}

.corner {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.categoryItem+.categoryItem {
    margin-left: 10px;
}

.video {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.videoAdd {
    padding: 20px;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    line-height: 1.5;
}

.videobox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.refresh {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #fff;

}
.myerr{
    position: absolute;
    top: 70px;
    left: 0;
}
.c-f00{

    color: #F56C6C;
}
</style>